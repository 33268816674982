export default {
  membersReport: 'can see members report',
  membersCashBack: 'can see members cash back',
  editMember: 'can edit member',
  toggleMemberPro: 'can toggle member pro',
  toggleMemberInTheChirpyest: 'can toggle member in "the chirpyest"',
  toggleMemberIsCreator: 'can toggle member is creator',
  toggleMemberNewsletter: 'can toggle member newsletter subscription',
  brandsReport: 'can see brands report',
  brandsTotalCommission: 'can see brands total commission',
  editBrands: 'can edit brands',
  editBrandsCheckoutUrls: 'can edit brands checkout urls',
  editBrandsXpathPatterns: 'can edit brands xpath patterns',
  editBrandsCommissionException: 'can edit brands commission exception',
  editBrandsCategories: 'can edit brands categories',
  toggleBrandsCashBackShowing: 'can toggle brands cash back showing',
  topPerformingBrands: 'can see top performing brands',
  newBrandsReport: 'can see new brands report',
  brandsChangelogReport: 'can see brands changelog report',
  totalRevenuesReport: 'can see total revenues report',
  transactionsReport: 'can see transactions report',
  transactionsUser: 'can see transactions user info',
  transactionsCommissions: 'can see transactions commissions',
  editTransactions: 'can edit transactions',
  cashBackPaidReport: 'can see cash back paid report',
  cashBackToPayReport: 'can see cash back to pay report',
  manuallySendCashBackPayments: 'can manually send cash back payments',
  referralBonusPaidReport: 'can see referral bonus paid report',
  referralBonusToPayReport: 'can see referral bonus to pay report',
  manuallySendReferralBonusPayments:
    'can manually send referral bonus payments',
  referralBonusPending: 'can see referral bonus pending',
  sharedCommissionPaidReport: 'can see shared commission paid report',
  sharedCommissionToPayReport: 'can see shared commission to pay report',
  manuallySendShareCommissionPayments:
    'can manually send share commission payments',
  adminCreateShareLinkTool: 'can see admin create share link tool',
  manageTopBanners: 'can manage top banners',
  manageCmsContent: 'can manage cms content',
  manageProductCategories: 'can manage product categories',
  manageProductCatalogues: 'can manage product catalogues',
  manageTrendingProducts: 'can manage trending products',
  manageTopProductsPerCategory: 'can manage top products per category',
  manageEditorPickPages: 'can manage editor pick pages',
  productParserLog: 'can see product parser log',
  shortLinksReport: 'can see share links report',
  revenuesAnalytics: 'can see revenues analytics',
  membersAnalytics: 'can see members analytics',
  membersUsageAnalytics: 'can see members usage analytics',
  manageTopProducts: 'can manage top products',
  toggleMemberReferralBonus: 'can toggle member referral bonus',
  userDashboard: 'can see user dashboard',
  brandsTotals: 'can see total commission (revenue) amount in brands list',
  revenueTotals: 'can see totals row in total revenue table',
  transactionTotals: 'can see totals row in transactions table',
  exportMembers: 'can export members tab',
};
