import React, {
  createContext,
  useEffect,
  useReducer,
  FC,
  ReactNode,
} from 'react';
import { Auth } from '@aws-amplify/auth';
import axios from '../initializers/axios';
import LoadingScreen from '../components/LoadingScreen';

export interface User {
  id: string;
  email: string;
  name: string;
  [key: string]: any;
}

interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: any;
}

export interface AuthContextValue extends AuthState {
  method: 'Cognito';
  login: (options?: any) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user,
      };
    }
    case 'LOGIN': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'Cognito',
  login: () => Promise.resolve(),
  logout: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (options) => {
    const loginSesssion = await Auth.signIn(options.email, options.password);
    const user = loginSesssion.getSignInUserSession().getIdToken().payload;
    const res = await axios.get(`/admin/users/${user['custom:user_id']}`);

    dispatch({
      type: 'LOGIN',
      payload: {
        user: {
          id: user['custom:user_id'],
          email: user.email,
          name: user.name,
          cognitoId: user.sub,
          userRole: user['custom:user_role'],
          roles: res.data.data.roles,
        },
      },
    });
  };

  const logout = async () => {
    await Auth.signOut();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    const initialise = async () => {
      try {
        const auth = await Auth.currentSession();
        const isAuthenticated = auth.isValid();

        if (isAuthenticated) {
          const user = await auth.getIdToken().payload;
          const res = await axios.get(`/admin/users/${user['custom:user_id']}`);

          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: {
                id: user['custom:user_id'],
                email: user.email,
                name: user.name,
                cognitoId: user.sub,
                userRole: user['custom:user_role'],
                roles: res.data.data.roles,
              },
            },
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated,
              user: null,
            },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'Cognito',
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
