import axios from 'axios';
import { Auth } from 'aws-amplify';

// TODO: Refactor this to use global Auth instance from the appState
const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
instance.defaults.headers.post['Content-Type'] = 'application/json';
instance.defaults.headers.common['X-Source'] = 'WEBSITE';

instance.interceptors.request.use(function (config) {
  return Auth.currentSession()
    .then((session) => {
      config.headers.Authorization = `Bearer ${session
        .getAccessToken()
        .getJwtToken()}`;
      return Promise.resolve(config);
    })
    .catch(() => {
      return Promise.reject(config);
    });
});

export default instance;
