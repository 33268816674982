import ENDPOINTS from './endpoints';
import NAVIGATION_URLS from './navigationUrls';
import ROLES from './roles';
import USERTYPES from './userTypes';
import * as NETWORKS from './networks';

const COLORS = {
  lightGrey: '#ABABAB',
  whiteStain: '#FAFBFD',
  dangerRed: '#FF3860',
  ashGrey: '#CDCDCD',
  black: '#000000',
  watermelonPink: '#F27870',
  gainsboro: '#ddd',
  infoBlue: '#0D3C61',
  successGreen: 'green',
  white: '#FFFFFF',
  darkGrey: '#737373',
  darkGrey2: '#000000DE',
  lightBlack: '#232323',
};
const FONTS = {
  Schnyder: {
    SchnyderLLight: 'Schnyder L Light',
    SchnyderLBoldItalic: 'Schnyder L Bold Italic',
    SchnyderLBold: 'Schnyder L Bold',
    SchnyderLDemiItalic: 'Schnyder L Demi Italic',
    SchnyderLDemi: 'Schnyder L Demi',
    SchnyderLLightItalic: 'Schnyder L Light Italic',
    SchnyderMBoldItalic: 'Schnyder M Bold Italic',
    SchnyderMBold: 'Schnyder M Bold',
    SchnyderMDemiItalic: 'Schnyder M Demi Italic',
    SchnyderMDemi: 'Schnyder M Demi',
    SchnyderMLightItalic: 'Schnyder M Light Italic',
    SchnyderMLight: 'Schnyder M Light',
    SchnyderSBoldItalic: 'Schnyder S Bold Italic',
    SchnyderSBold: 'Schnyder S Bold',
    SchnyderSDemiItalic: 'Schnyder S Demi Italic',
    SchnyderSLightItalic: 'Schnyder S Light Italic',
    SchnyderSLight: 'Schnyder S Light',
    SchnyderXLBoldItalic: 'Schnyder XL Bold Italic',
    SchnyderXLBold: 'Schnyder XL Bold',
    SchnyderXLDemiItalic: 'Schnyder XL Demi Italic',
    SchnyderXLDemi: 'Schnyder XL Demi',
    SchnyderXLLightItalic: 'Schnyder XL Light Italic',
    SchnyderXLLight: 'Schnyder XL Light',
  },
  Graphik: {
    GraphikBlack: 'Graphik-Black',
    GraphikBlackItalic: 'Graphik-BlackItalic',
    GraphikBold: 'Graphik-Bold',
    GraphikBoldItalic: 'Graphik-BoldItalic',
    GraphikExtralight: 'Graphik-Extralight',
    GraphikExtralightItalic: 'Graphik-ExtralightItalic',
    GraphikLight: 'Graphik Light',
    GraphikLightItalic: 'Graphik-LightItalic',
    GraphikMedium: 'Graphik Medium',
    GraphikMediumItalic: 'Graphik-MediumItalic',
    GraphikRegular: 'Graphik Regular',
    GraphikRegularItalic: 'Graphik-RegularItalic',
    GraphikSemibold: 'Graphik-Semibold',
    GraphikSemiboldItalic: 'Graphik-SemiboldItalic',
    GraphikSuper: 'Graphik-Super',
    GraphikSuperItalic: 'Graphik-SuperItalic',
    GraphikThin: 'Graphik-Thin',
    GraphikThinItalic: 'Graphik-ThinItalic',
  },
};

const WIDTH_DIMENSIONS = {
  max: 1650,
};

const BREAKPOINTS = {
  xsm: 320,
  sm: 480,
  xmd: 576,
  md: 768,
  mdd: 960,
  lg: 1024,
  xl: 1200,
  xml: 1360,
  xxl: 1400,
  xlg: 1600,
  xxlg: 1840,
};
export const EXPORT_SHEET_TYPES = {
  ACTIVE_BRANDS: 'active brands',
  INACTIVE_BRANDS: 'in active brands',
  MEMBERS: 'members',
  TOTAL_REVENUE: 'total revenue',
  TRANSACTIONS: 'transactions',
  SHARE_LINKS: 'share links',
  REFERRAL_BONUS_TO_PAY: 'referral bonus to pay',
  ADMINS: 'admins',
  PRODUCT_PARSER_LOGS: 'product parser logs',
  TOP_PERFORMING_BRANDS: 'top performing brands',
  NEW_BRANDS: 'new brands',
  CASHBACK_TO_PAY: 'cashback to pay',
  CASHBACK_PAID: 'cashback paid',
};
export {
  ENDPOINTS,
  NAVIGATION_URLS,
  ROLES,
  USERTYPES,
  NETWORKS,
  COLORS,
  FONTS,
  BREAKPOINTS,
  WIDTH_DIMENSIONS,
};
