import React from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { checkRole } from '../utils/permissions';
import { USERTYPES } from '../constants';

const RolesGuard = ({ children, allowedRoles }) => {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated || !USERTYPES[user.userRole]) {
    return <Redirect to="/login" />;
  }
  return checkRole({ user, allowedRoles }) ? children : null;
};

export default RolesGuard;
