import React, { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';
import { Redirect } from 'react-router-dom';
import { USERTYPES } from '../constants';

interface GuestGuardProps {
  children?: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const { isAuthenticated, user } = useAuth();

  if (isAuthenticated && USERTYPES[user.userRole]) {
    return <Redirect to="/app/members" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
