import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import Amplify from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import App from './App';
import { theme } from './theme';
import awsConfig from './initializers/aws-exports';
import './i18n';
import { SnackbarProvider } from 'notistack';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

Amplify.configure(awsConfig);
Auth.configure(awsConfig);
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  environment: process.env.REACT_APP_NAME || 'localhost',
  integrations: [new BrowserTracing()],
});

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback="loading">
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            variant="error"
          >
            <App />
          </SnackbarProvider>
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);
