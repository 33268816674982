import { USERTYPES } from '../constants';

export const checkRole = ({ user, allowedRoles }) => {
  const ROLES = user?.roles || [];
  const userType = user?.userRole || '';

  if (userType === USERTYPES['super-admin']) {
    return true;
  }

  for (const role of ROLES) {
    if (allowedRoles.includes(role)) {
      return true;
    }
  }

  return false;
};

export const checkRoleV2 = ({ user, allowedRoles }) => {
  const ROLES = user?.permissions || [];
  const userType = user?.role || '';

  if (userType === USERTYPES['super-admin']) {
    return true;
  }

  for (const role of ROLES) {
    if (allowedRoles.includes(role)) {
      return true;
    }
  }

  return false;
};
