import { responsiveFontSizes } from '@material-ui/core/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import graphikLight from './FontFamilies/Graphik/Graphik-Light.otf';
import graphikRegular from './FontFamilies/Graphik/Graphik-Regular.otf';
import graphikMedium from './FontFamilies/Graphik/Graphik-Medium.otf';
import schnyderXLLight from './FontFamilies/Schnyder/Schnyder-XL-Light.otf';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    status: {
      danger: React.CSSProperties['color'];
    };
  }
}

let theme = createMuiTheme({
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      disableRipple: true,
    },
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: '#FF0000',
    },
    background: {
      default: '#fff',
    },
  },
  status: {
    danger: '#e53e3e',
  },
  typography: {
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      fontFamily: 'Schnyder XL Light',
      fontSize: '1.875rem' /*equals 30px*/,
      lineHeight: 1.1,
      marginBottom: 20,
    },
    body1: {
      fontFamily: 'Schnyder XL Light',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          {
            fontFamily: 'Graphik Medium',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikMedium}) format('opentype')`,
          },
          {
            fontFamily: 'Graphik Regular',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikRegular}) format('opentype')`,
          },
          {
            fontFamily: 'Graphik Light',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${graphikLight}) format('opentype')`,
          },
          {
            fontFamily: 'Schnyder XL Light',
            fontStyle: 'normal',
            fontDisplay: 'swap',
            fontWeight: 400,
            src: `url(${schnyderXLLight}) format('opentype')`,
          },
        ],
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
