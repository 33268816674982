export const networks = {
  impactRadius: 1,
  rakuten: 2,
  cj: 3,
  partnerize: 4,
  shareASale: 5,
  pepperjam: 6,
  awin: 7,
  ebay: 8,
};
