const COLORS = {
  whiteStain: '#FAFBFD',
  whiteStain2: '#F7F7F7',
  whiteStain3: '#D8D9E1',
  dangerRed: '#FF3860',
  ashGrey: '#CDCDCD',
  darkGrey: '#737373',
  white: '#FFFFFF',
  lightGrey: '#ABABAB',
  darkGrey2: '#000000DE',
  infoBlue: '#0D3C61',
  black: '#000000',
  gray: '#F2F2F3',
};

export default COLORS;
