import React, { Fragment, lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import RolesGuard from './components/RolesGuard';
import { ROLES } from './constants';
import DashboardLayout from './layouts/DashboardLayout';
import MainLayout from './layouts/MainLayout';

type Routes = {
  exact?: boolean;
  path?: string;
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
  roles?: string[];
}[];

export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense
    fallback={
      <div style={{ width: '100%' }}>
        <LoadingScreen />
      </div>
    }
  >
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;
        const allowedRoles = route.roles || [];

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard allowedRoles={allowedRoles}>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

// prettier-ignore
const routes: Routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('./views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('./views/auth/LoginView')),
  },
  {
    exact: true,
    path: '/app/user/dashboard/:id',
    guard: RolesGuard,
    roles: [ROLES.userDashboard],
    component: lazy(()=> import('./views/userDashboard'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app/members',
        guard: RolesGuard,
        roles: [ROLES.membersReport],
        component: lazy(() => import('./views/members/MembersListView')),
      },
      {
        exact: true,
        path: '/app/members/:id',
        guard: RolesGuard,
        roles: [ROLES.membersReport],
        component: lazy(() => import('./views/members/MemberProfileView')),
      },
      {
        exact: true,
        path: '/app/brands/all',
        roles: [ROLES.brandsReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/brands/AllBrandsView')),
      },
      {
        exact: true,
        path: '/app/brands/top',
        roles: [ROLES.topPerformingBrands],
        guard: RolesGuard,
        component: lazy(() => import('./views/brands/TopBrandsView')),
      },
      {
        exact: true,
        path: '/app/brands/new',
        roles: [ROLES.newBrandsReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/brands/NewBrandsView')),
      },
      {
        exact: true,
        path: '/app/brands/bulk-update-user-commission',
        roles: [ROLES.editBrands],
        guard: RolesGuard,
        component: lazy(() => import('./views/brands/BulkUpdateUserBrandCommission')),
      },
      {
        exact: true,
        path: '/app/brands/changelog',
        roles: [ROLES.brandsChangelogReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/brands/BrandsChangelogView')),
      },
      {
        exact: true,
        path: '/app/brands',
        component: () => <Redirect to="/app/brands/all" />,
      },
      {
        exact: true,
        path: '/app/revenues',
        roles: [ROLES.totalRevenuesReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/revenues/RevisedRevenuesView')),
      },
      {
        exact: true,
        path: '/app/transactions',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/AllTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/rakuten',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/RakutenTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/impact',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/ImpactTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/cj',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/CjTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/partnerize',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/PartnerizeTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/shareASale',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/ShareASaleTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/pepperjam',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/PepperjamTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/awin',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/AwinTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/transactions/ebay',
        roles: [ROLES.transactionsReport],
        guard: RolesGuard,
        component: lazy(
          () => import('./views/transactions/EbayTransactionsView')
        ),
      },
      {
        exact: true,
        path: '/app/payments/cashback',
        roles: [ROLES.cashBackPaidReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/payments/PaymentsListView')),
      },
      {
        exact: true,
        path: '/app/payments/cashback-to-pay',
        roles: [ROLES.cashBackToPayReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/payments/ToPayListView')),
      },
      {
        exact: true,
        path: '/app/payments/referral-bonus',
        roles: [ROLES.referralBonusPaidReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/referralsPayments/PaidListView')),
      },
      {
        exact: true,
        path: '/app/payments/referral-bonus-to-pay',
        roles: [ROLES.referralBonusToPayReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/referralsPayments/ToPayListView')),
      },
      {
        exact: true,
        path: '/app/payments/pending-referral-bonus',
        roles: [ROLES.referralBonusPending],
        guard: RolesGuard,
        component: lazy(() => import('./views/referralsPayments/PendingListView')),
      },
      {
        exact: true,
        path: '/app/paid-shared-commissions',
        roles: [ROLES.sharedCommissionPaidReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/proSharedCommissions/SharedCommissionsListView')),
      },
      {
        exact: true,
        path: '/app/to-pay-shared-commissions',
        roles: [ROLES.sharedCommissionToPayReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/proSharedCommissions/ToPayListView')),
      },
      {
        exact: true,
        path: '/app/rev-share',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/revshare')),
      },
      {
        exact: true,
        path: '/app/rev-share-list',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/revshare/RevShareList')),
      },
      {
        exact: true,
        path: '/app/settings',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/settings')),
      },
      {
        exact: true,
        path: '/app/join-pages',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/joinPageSettings/')),
      },
      {
        exact: true,
        path: '/app/export-logs',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/exportLogs')),
      },
      {
        exact: true,
        path: '/app/share-with-friends',
        component: lazy(() => import('./views/createShareLink')),
      },
      {
        exact: true,
        path: '/app/subscription',
        component: lazy(() => import('./views/subscription')),
      },
      {
        exact: true,
        path: '/app/admins',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/admins/AdminsListView')),
      },
      {
        exact: true,
        path: '/app/admins/:id',
        roles: [],
        guard: RolesGuard,
        component: lazy(() => import('./views/admins/AdminProfileView')),

      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/members" />,
      },
      {
        exact: true,
        path: '/app/banner',
        roles: [ROLES.manageTopBanners],
        guard: RolesGuard,
        component: lazy(() => import('./views/topBanner')),
      },
      {
        exact: true,
        path: '/app/banner/:type',
        roles: [ROLES.manageTopBanners],
        guard: RolesGuard,
        component: lazy(() => import('./views/topBannerIOS')),
      },
      {
        exact: true,
        path: '/app/cms',
        roles: [ROLES.manageCmsContent],
        guard: RolesGuard,
        component: lazy(() => import('./views/cmsEditor')),
      },
      {
        exact: true,
        path: '/app/categories',
        roles: [ROLES.manageProductCategories],
        guard: RolesGuard,
        component: lazy(() => import('./views/categories')),
      },
      {
        exact: true,
        path: '/app/product-catalogues',
        roles: [ROLES.manageProductCatalogues],
        guard: RolesGuard,
        component: lazy(() => import('./views/productCatalogues')),
      },
      {
        exact: true,
        path: '/app/trending-products',
        roles: [ROLES.manageTrendingProducts],
        guard: RolesGuard,
        component: lazy(() => import('./views/trending')),
      },
      {
        exact: true,
        path: '/app/top-products',
        roles: [ROLES.manageTopProducts],
        guard: RolesGuard,
        component: lazy(() => import('./views/topProducts')),
      },
      {
        exact: true,
        path: '/app/top-category-products',
        roles: [ROLES.manageTopProductsPerCategory],
        guard: RolesGuard,
        component: lazy(() => import('./views/topCategoryProducts')),
      },
      {
        exact: true,
        path: '/app/product-parser-logs',
        roles: [ROLES.productParserLog],
        guard: RolesGuard,
        component: lazy(() => import('./views/productParserLogs')),
      },
      {
        exact: true,
        path: '/app/payment-held-logs',
        roles: [ROLES.membersReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/members/PaymentHeldLogsView')),
      },
      {
        exact: true,
        path: '/app/edit-pages',
        roles: [ROLES.manageEditorPickPages],
        guard: RolesGuard,
        component: lazy(() => import('./views/editPages')),
      },
      {
        exact: true,
        path: '/app/edit-pages/create',
        roles: [ROLES.manageEditorPickPages],
        guard: RolesGuard,
        component: lazy(() => import('./views/editPages/createPage')),
      },
      {
        exact: true,
        path: '/app/edit-pages/edit/:pageId',
        roles: [ROLES.manageEditorPickPages],
        guard: RolesGuard,
        component: lazy(() => import('./views/editPages/editPage')),
      },
      {
        exact: true,
        path: '/app/short-links',
        roles: [ROLES.shortLinksReport],
        guard: RolesGuard,
        component: lazy(() => import('./views/shortLinks')),
      },
      {
        exact: true,
        path: '/app/dashboard/analytics',
        roles: [ROLES.revenuesAnalytics, ROLES.membersAnalytics, ROLES.membersUsageAnalytics],
        guard: RolesGuard,
        component: lazy(() => import('./views/analytics')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/app/members" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
