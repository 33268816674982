import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ListView from './ListView';
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { COLORS, FONTS } from '../../../theme';
import useAuth from './../../../hooks/useAuth';

const user = {
  avatar: '/static/images/avatars/avatar_6.png',
  name: 'cococozy',
};

interface RenderTree {
  id: string;
  title: string;
  icon?: React.ReactNode;
  children?: RenderTree[];
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: '25%',
    maxWidth: 325,
    backgroundColor: COLORS.whiteStain,
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
  name: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
    color: COLORS.ashGrey,
    paddingTop: 16,
  },
  logOut: {
    fontFamily: FONTS.Graphik.GraphikMedium,
    fontSize: '0.75rem',
    color: COLORS.darkGrey,
    paddingTop: 16,
  },
}));

const NavBar = ({ onMobileClose, openMobile }: any) => {
  const classes: any = useStyles();
  const location = useLocation();
  const userInfo = useAuth();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          src={user.avatar}
          to={'/app/members'}
        />
        <Typography className={classes.name} color="textPrimary" variant="h5">
          {userInfo.user.email}
        </Typography>
        <Hidden lgUp>
          <Typography
            onClick={async () => {
              await userInfo.logout();
            }}
            className={classes.logOut}
            color="textPrimary"
            variant="h5"
          >
            logout
          </Typography>
        </Hidden>
      </Box>
      <Divider />
      <Box p={2}>
        <ListView />
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
