const FONTS = {
  Schnyder: {
    SchnyderXLLight: 'Schnyder XL Light',
    SchnyderLLight: 'Schnyder L Light',
    SchnyderLDemi: 'Schnyder L Demi',
  },
  Graphik: {
    GraphikLight: 'Graphik Light',
    GraphikMedium: 'Graphik Medium',
    GraphikRegular: 'Graphik Regular',
  },
};

export default FONTS;
