import React from 'react';
import routes, { renderRoutes } from './routes';
import { AuthProvider } from './contexts/AmplifyAuthContext';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

function App() {
  return (
    <AuthProvider>
      <DndProvider backend={HTML5Backend}>{renderRoutes(routes)}</DndProvider>
    </AuthProvider>
  );
}

export default App;
