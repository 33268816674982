import React, { FC } from 'react';
import { Box, makeStyles, Theme } from '@material-ui/core';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import Loader from 'react-loader-spinner';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    minHeight: '100%',
    padding: theme.spacing(3),
  },
}));

const LoadingScreen: FC = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box width={400}>
        <Loader type="Oval" color="#777" height={40} width={40} />
      </Box>
    </div>
  );
};

export default LoadingScreen;
